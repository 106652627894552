<template>
    <b-modal :title="$t('config.tables.rearrange_tables')" v-model="modalActive">
        <span>{{$t('config.tables.tables')}}</span>
        <draggable v-model="tablesTmp">
            <div style="cursor: pointer;" v-for="(table, index) in tablesTmp" :key="table.id" class="mt-1 mb-1">
                <fa icon="arrows-up-down" color="#B4BFB5" />
                <span :class="{'disabled-product': table.disabled}">
                    {{index + 1}} - {{table.name}}
                </span>
            </div>
        </draggable>

        <template #modal-footer>
            <b-button variant="primary" @click="orderByName">
                <feather-icon icon="MoveIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('keys.product_display_groups.order_by_name') }}</span>
            </b-button>
            <b-button variant="primary" @click="rearrange">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">{{ $t('general.edit') }}</span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BModal} from 'bootstrap-vue'
    import draggable from 'vuedraggable'
    export default {
        components: {
            draggable,
            BButton,
            BModal
        },
        props: {
            tables: {type: Array}
        },
        data() {
            return {
                tablesTmp: [],
                modalActive: false
            }
        },
        methods: {
            open() {
                this.tablesTmp = this.tables
                this.modalActive = true
            },
            orderByName() {
                this.tablesTmp.sort((a, b) => a.name.localeCompare(b.name))
            },
            rearrange() {
                this.modalActive = false
                this.$emit('rearrange', this.tablesTmp)
            }
        }
    }
</script>