export const Shapes = {
    Rectangle: 1,
    Circle: 2
}

export const Sides = {
    Top: 1,
    Bottom: 2,
    Left: 3,
    Right: 4
}