<template>
    <b-modal :title="$t('config.work_premises.building_part')" v-model="modalActive" no-close-on-backdrop>
        <template #default>
            <validation-observer ref="validation">
                <b-form-group :label="$t('form_data.name')">
                    <validation-provider #default="{ errors }" :name="$t('form_data.name')" rules="required">
                        <b-form-input autofocus v-model="addObject.name"/>
                        <small class="text-danger">{{errors[0]}}</small>
                    </validation-provider>
                </b-form-group>
            </validation-observer>
        </template>

        <template #modal-footer>
            <b-button variant="primary" @click="validate">
                <span v-if="editing">
                    <feather-icon icon="EditIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.edit') }}</span>
                </span>
                <span v-else>
                    <feather-icon icon="PlusIcon" class="mr-50"/>
                    <span class="align-middle">{{ $t('general.add') }}</span>
                </span>
            </b-button>
        </template>
    </b-modal>
</template>
<script>
    import {BButton, BFormGroup, BFormInput, BModal} from 'bootstrap-vue'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required } from '@validations'
    import * as Sentry from '@sentry/vue'

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BModal,
            BButton,
            /*BCard,
            BRow,
            BCol,*/
            ValidationProvider,
            ValidationObserver
            //vSelect

        },
        props: {
            selectedbusinessUnit: {type: String}
        },

        data() {
            return {
                modalActive: false,
                addObject: {
                    name: '',
                    business_unit_id: '',
                    tables: []
                },
                types: [{id: 1, name: this.$t('config.tables.rectangle')}, {id: 2, name: this.$t('config.tables.circle')}],
                required,
                editing: false
            }
        },
        methods: {
            open(data = null) {
                if (data !== null) {
                    this.addObject = data
                    this.editing = true
                } else {
                    this.editing = false
                    this.addObject = {
                        name: '',
                        tables: []
                    }
                }
                this.modalActive = true
            },
            validate() {
                this.$refs.validation.validate().then((success) =>  {
                    if (success) {
                        if (this.editing) this.edit()
                        else this.add()
                    }
                })
            },
            /*setChairs() {
                for (const table of this.addObject.tables) {
                    if (table.shape_type === 1) {
                        const chairs = table.chairs
                        table.chairs = []
                        table.chairs.push(chairs.top)
                        table.chairs.push(chairs.right)
                        table.chairs.push(chairs.down)
                        table.chairs.push(chairs.left)
                    } else {
                        const chairs = table.chairs
                        table.chairs = []
                        table.chairs.push(chairs.circle)
                    }
                }
                this.add()
            },*/
            async add() {
                try {
                    this.addObject.business_unit_id = this.selectedbusinessUnit
                    const response = await this.$http.post('/api/client/v1/work_premises/', this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded', response.data.id)
                    this.$printSuccess(this.$t('print.success.add'))
                    this.addObject = {
                        name: '',
                        business_unit_id: '',
                        tables: []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_add'))
                }
            },
            async edit() {
                try {
                    await this.$http.put(`/api/client/v1/work_premises/${this.addObject.id}`, this.addObject)
                    this.modalActive = false
                    this.$emit('itemAdded')
                    this.$printSuccess(this.$t('print.success.edit'))
                    this.addObject = {
                        name: '',
                        business_unit_id: '',
                        tables: []
                    }
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                }
            }
            /*addChairs(roomId) {
                const thisIns = this
                thisIns.$http.put(`/api/client/v1/business_unit_rooms/${roomId}/tables`, this.addObject.tables)
                    .then(function(response) {
                        thisIns.modalActive = false
                        thisIns.$emit('itemAdded', response.data.id)
                        thisIns.$printSuccess(thisIns.$t('general.add_success'))
                        thisIns.addObject = {
                            name: '',
                            tables: []
                        }
                    }).catch(function(error) {
                        thisIns.$printError(error.message)
                    })
            }*/
        }
    }
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>