<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('menu.subcategory.work_premises')">
            <v-select v-if="businessUnits.length > 0" :clearable="false" class="w-100 mb-2" v-model="selectedbusinessUnit" :options="businessUnits" label="name" @input="loadSelectedbusinessUnitData" />
            <b-alert show v-else-if="!showLoader" variant="warning" class="p-1 mt-2">
                {{ $t('config.table_empty') }} <router-link to="business_units">{{ $t('config.here') }}</router-link>
            </b-alert>

            <div class="d-flex justify-content-end" v-if="$hasPermission($permissions.ConfigurationWrite)">
                <b-button variant="primary" :disabled="businessUnits && businessUnits.length === 0" @click="$refs.addModal.open()">
                    <feather-icon icon="PlusSquareIcon"/>
                    {{ $t('config.work_premises.add_building_part') }}
                </b-button>
            </div>

            <Table class="mt-1" :columnDefs="columnDefs" :rowData="workPremisess" @deleteRow="remove" @editRow="editRow" @edit="edit" @viewTables="viewTables"/>
        </b-card>
        <div v-if="selectedbusinessUnit">
            <AddWorkPremises ref="addModal" v-on:itemAdded="loadData" :selectedbusinessUnit="selectedbusinessUnit.id"/>
        </div>
    </b-overlay>
</template>

<script>
    import Table from '@/views/components/Table/Table'
    import AddWorkPremises from '@/views/WorkPremises/AddWorkPremises'
    import vSelect from 'vue-select'
    import {BAlert, BButton, BCard, BOverlay} from 'bootstrap-vue'
    import * as Sentry from '@sentry/vue'
    import itemChange from '@core/mixins/installation-wizard/itemChange'

    export default {
        mixins: [itemChange],
        components: {Table, AddWorkPremises, vSelect, BAlert, BCard, BOverlay, BButton},
        props: {
            buttonType: {
                type: String,
                default: 'ViewTables'
            }
        },
        computed: {

            columnDefs() {
                const hasPermission =  this.$hasPermission(this.$permissions.ConfigurationWrite)

                const defs = [
                    { headerName: this.$t('table_fields.name'), editable: hasPermission, field: 'name', filter: true},
                    { headerName: this.$t('table_fields.tables'), editable: false, field: 'tables', filter: true, cellRenderer: (params) => this.getTables(params.value)}
                ]

                if (hasPermission) {
                    defs.push(
                        { headerName: this.$t('table_fields.actions'), editable: false, filter: false, sortable: false, colId:'actions', cellRenderer: 'btnCellRenderer', cellRendererParams: {button: this.buttonType}, minWidth: 150}
                    )
                }
                return defs
            }
        },
        data() {
            return {
                workPremisess: [],
                businessUnits: [],
                showLoader: false,
                selectedbusinessUnit: ''
            }
        },
        methods: {
            viewTables(id) {
                this.$emit('viewWorkPremise', id)
            },
            async loadData() {
                try {
                    this.showLoader = true

                    const responsebusinessUnits = await this.$http.get('/api/client/v1/business_units/')
                    this.businessUnits = responsebusinessUnits.data ?? []

                    //set first businessUnit as default at first load
                    if (this.businessUnits && this.businessUnits.length > 0 && !this.selectedbusinessUnit) {
                        const responseWorkPremisessAtLoad = await this.$http.get(`/api/client/v1/work_premises/business_units/${this.businessUnits[0].id}`)
                        this.workPremisess = responseWorkPremisessAtLoad.data ?? []

                        this.selectedbusinessUnit = this.businessUnits[0]
                    } else {
                        const responseWorkPremisessAtLoad = await this.$http.get(`/api/client/v1/work_premises/business_units/${this.selectedbusinessUnit.id}`)
                        this.workPremisess = responseWorkPremisessAtLoad.data ?? []
                    }

                    await this.itemChange()
                    this.$emit('reload')

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async loadSelectedbusinessUnitData() {
                try {
                    this.showLoader = true

                    const responseWorkPremisess = await this.$http.get(`/api/client/v1/work_premises/business_units/${this.selectedbusinessUnit.id}`)
                    this.workPremisess = responseWorkPremisess.data ?? []

                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_load_data'))
                } finally {
                    this.showLoader = false
                }
            },
            async edit(params) {
                try {
                    this.showLoader = true

                    const editObject = {object_data:{[params.column.colId]: params.newValue}}
                    await this.$http.patch(`/api/client/v1/work_premises/${params.data.id}`, editObject)
                    this.$printSuccess(this.$t('print.success.edit'))
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_edit'))
                } finally {
                    this.showLoader = false
                    await this.loadData()
                }
            },
            editRow(data) {
                this.$refs.addModal.open(data)
            },
            async remove(id) {
                try {
                    const confirmDelete = await this.$confirmDialog(this.$t('print.confirm.delete_row'))
                    if (!confirmDelete) return
                    await this.$http.delete(`/api/client/v1/work_premises/${id}`)
                    this.$printSuccess(this.$t('print.success.delete'))
                    await this.loadData()
                } catch (err) {
                    Sentry.captureException(err)
                    this.$printError(this.$t('print.error.on_delete'))
                }
            },
            getTables(value) {
                if (value && value.length > 0) return value.map(option => { return `${option.name}` }).join(', ')
                else return '/'
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>

<style scoped>

</style>
