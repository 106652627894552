<template>
    <b-overlay :show="showLoader">
        <WorkPremises v-if="step === 0" @viewWorkPremise="viewWorkPremise" @reload="$emit('load')"  />
        <ViewRoom v-else-if="step === 1" ref="viewRoomSubpage" :work-premise-id="this.workPremiseId" @reload="$emit('load')" @back="step = 0" />
    </b-overlay>
</template>

<script>
    import { BOverlay } from 'bootstrap-vue'
    import WorkPremises from '@/views/WorkPremises/WorkPremises.vue'
    import ViewRoom from '@/views/WorkPremises/ViewRoom.vue'

    export default {
        components:{ViewRoom, WorkPremises, BOverlay },
        data() {
            return {
                step: 0,
                workPremiseId: null,
                showLoader: false
            }
        },
        methods: {
            viewWorkPremise(id) {
                this.workPremiseId = id
                this.step = 1
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>