<template>
    <div>
        <b-button v-if="params.data.x === 0 && params.data.y === 0" class="m-0 button" variant="secondary" @click="addTableToRoom">{{ $t('general.add') }}</b-button>
        <span v-else>/</span>
    </div>
</template>

<script>
    import {BButton} from 'bootstrap-vue'
    export default {
        components: {BButton},
        methods: {
            addTableToRoom() {
                this.$root.$emit('addTable', this.params.data)
            }
        }
    }
</script>

<style scoped>
.button {
  padding: 0.4rem;
}
</style>